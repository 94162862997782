<template>
  <div class="base-list-page">
    <el-form
      :model="searchForm"
      @keyup.enter.native="getDataList(1)"
      ref="dataForm"
      inline
      class="bysearchForm"
    >
      <div class="searchLine">
        <el-form-item label="公司" class="typeInput">
          <el-select
            v-model="searchForm.companyIds"
            multiple
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in companyList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="员工姓名" class="typeInput">
          <el-input
            v-model="searchForm.employeesName"
            placeholder="员工姓名"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item label="用户ID" class="typeInput">
          <el-input
            v-model="searchForm.uuid"
            placeholder="用户ID"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item label="手机号码" class="typeInput">
          <el-input
            v-model="searchForm.mobile"
            placeholder="手机号码"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item label="部门" class="typeInput">
          <el-cascader
            v-model="searchForm.companyAndDepartment"
            :options="options"
            :props="{ expandTrigger: 'hover', multiple: true }"
          >
          </el-cascader>
        </el-form-item>

        <el-form-item label="日期" class="typeInput">
          <el-date-picker
            v-model="date"
            class="el-popper DatepickerTime"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            clearable
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item class="typeInput">
          <el-button @click="getDataList(1)">查询</el-button>
          <el-button
            type="primary"
            size="mini"
            @click="openReport()"
            v-if="isAuth('tc:employeesBalance:openReport')"
            >导出报表</el-button
          >
        </el-form-item>
      </div>
    </el-form>
    <el-table
      :data="dataList"
      border
      stripe
      v-loading="dataListLoading"
      ref="dataList"
      @selection-change="selectionChangeHandle"
    >
      <el-table-column
        prop="companyName"
        header-align="center"
        align="center"
        label="公司名称"
      >
      </el-table-column>
      <el-table-column
        prop="employeesName"
        header-align="center"
        align="center"
        label="员工姓名"
      >
      </el-table-column>
      <el-table-column
        prop="uuid"
        header-align="center"
        align="center"
        label="用户id"
      >
      </el-table-column>
      <el-table-column
        prop="mobile"
        header-align="center"
        align="center"
        label="手机号"
      >
      </el-table-column>
      <el-table-column
        prop="departmentName"
        header-align="center"
        align="center"
        label="部门"
      >
      </el-table-column>
      <el-table-column
        prop="date"
        header-align="center"
        align="center"
        label="日期"
      >
      </el-table-column>
      <el-table-column
        prop="beginBalance"
        header-align="center"
        align="center"
        label="期初余额"
      >
      </el-table-column>
      <el-table-column
        prop="inBalance"
        header-align="center"
        align="center"
        label="收入"
      >
      </el-table-column>
      <el-table-column
        prop="inCount"
        header-align="center"
        align="center"
        label="笔数"
      >
      </el-table-column>
      <el-table-column
        prop="outBalance"
        header-align="center"
        align="center"
        label="支出"
      >
      </el-table-column>
      <el-table-column
        prop="outCount"
        header-align="center"
        align="center"
        label="笔数"
      >
      </el-table-column>
      <el-table-column
        prop="endBalance"
        header-align="center"
        align="center"
        label="日终余额"
      >
      </el-table-column>
    </el-table>
    <list-footer>
      <el-pagination
        slot="right"
        class="right"
        background
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper"
      />
    </list-footer>
  </div>
</template>

<script>
import { listMixin, normal } from '@/mixins';
import { getCompanyList, getDepartmentList } from '@/utils/options.js';
export default {
  mixins: [listMixin, normal],
  data() {
    return {
      pageSize: 10,
      pageIndex: 1,
      totalPage: 0,
      searchForm: {
        companyAndDepartment: [],
        companyIds: [],
        employeesName: '',
        mobile: '',
        uuid: '',
        startDate: '',
        endDate: '',
      },
      totalList: {},
      date: [],
      options: [],
      companyList: [],
      addOrUpdateVisible: false,
    };
  },
  components: {},
  activated() {
    this.getDataList(1);
  },
  created() {
    this.getCompanyList();
    this.getDepartmentList();
  },
  methods: {
    // 获取数据列表
    getDataList(page) {
      if (this.date?.length) {
        this.searchForm.startDate = this.date[0];
        this.searchForm.endDate = this.date[1];
      } else {
        this.searchForm.startDate = '';
        this.searchForm.endDate = '';
      }
      let companyIds = this.searchForm.companyIds.join(',');
      let departmentIds = '';
      if (this.searchForm.companyAndDepartment.length !== 0) {
        companyIds = this.unique(
          this.searchForm.companyAndDepartment.map((item) => item[0]),
        ).join(',');
        departmentIds = this.searchForm.companyAndDepartment
          .map((item) => item[1])
          .join(',');
      }
      this.$getDataList({
        url: '/report/employees/employeesBalance',
        params: {
          page: page ? page : this.pageIndex,
          limit: this.pageSize,
          ...this.searchForm,
          companyIds: companyIds,
          departmentIds: departmentIds,
          startDate: this.searchForm.startDate,
          endDate: this.searchForm.endDate,
        },
        after: (data) => {
          if (data && data.code === 0) {
            this.dataList = data.page.list;
            this.totalList = data.total;
            // this.totalPage = data.page.totalCount;
          } else {
            this.dataList = [];
          }
        },
      });
    },

    getCompanyList() {
      getCompanyList().then(({ data }) => {
        if (data && data.code === 0) {
          this.companyList = data.list;
        }
      });
    },

    getDepartmentList() {
      getDepartmentList().then(({ data }) => {
        if (data && data.code === 0) {
          this.options = data.option.options;
        }
      });
    },

    openReport() {
      let companyIds = this.searchForm.companyIds.join(',');
      let departmentIds = '';
      if (this.searchForm.companyAndDepartment.length !== 0) {
        companyIds = this.unique(
          this.searchForm.companyAndDepartment.map((item) => item[0]),
        ).join(',');
        departmentIds = this.searchForm.companyAndDepartment
          .map((item) => item[1])
          .join(',');
      }
      window.open(
        `${process.env.VUE_APP_TABLE_BASE_URL}
      employees_balance.rptdesign&__showtitle=false&pageOverflow=2&__locale=zh_CN
      &company_ids=${companyIds}&employees_name=${this.searchForm.employeesName}
      &mobile=${this.searchForm.mobile}&department_ids=${departmentIds}
      &start_date=${this.searchForm.startDate}&end_date=${this.searchForm.endDate}
      &search_uuid=${this.searchForm.uuid}`.replaceAll(' ', ''),
      );
    },
    unique(arr) {
      // 根据唯一标识orderId来对数组进行过滤
      const res = new Map(); //定义常量 res,值为一个Map对象实例
      //返回arr数组过滤后的结果，结果为一个数组   过滤条件是，如果res中没有某个键，就设置这个键的值为1
      return arr.filter((arr) => !res.has(arr) && res.set(arr, 1));
    },
  },
};
</script>
